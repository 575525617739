export enum MainSection {
  reports = "reports",
  /**
   * Includes inbox, channels
   */
  inbox = "inbox",
  aiOutbound = "aiOutbound",
  phone = "phone",
  "help-center" = "help-center",
  contacts = "contacts",
  settings = "settings",
  aiEmployee = "aiEmployee",
  channels = "channels",
}
