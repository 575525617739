"use client";

import { useActiveSection } from "app/_hooks/useActiveSection";
import { startCase } from "lodash";
import { useEffect, useMemo } from "react";

function headerCase(str: string) {
  return startCase(str)
    .split(" ")
    .map((w) => (w === "Ai" ? "AI" : w))
    .join(" ");
}

export function DocumentTitleManager() {
  const { activeMainSection, activeSubSection } = useActiveSection();

  const title = useMemo(() => {
    if (!activeMainSection) return "Open";
    if (!activeSubSection) return headerCase(activeMainSection);
    if (
      activeMainSection.toString().toLowerCase() ===
      activeSubSection.toString().toLowerCase()
    )
      return headerCase(activeMainSection);

    return `${headerCase(activeMainSection)} | ${headerCase(activeSubSection)}`;
  }, [activeMainSection, activeSubSection]);

  useEffect(() => {
    document.title = title;
  }, [title]);

  return null;
}
