"use client";
import {
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@openchatai/hoose/ui";
import { createSafeContext } from "lib/createSafeContext";
import { Store, useStore } from "lib/store";
import _ from "lodash";
import { ReactNode, useMemo } from "react";

type DialogType = {
  id: string;
  open: boolean;
  title: string;
  description?: string;
  content?:
    | ReactNode
    | ((dialoger: DialogStore, dialog: DialogType) => ReactNode);
  body?: ReactNode; // replaces the whole content
  cancelText?: string;
  confirmText?: string;
  onConfirm?: (dialoger: DialogStore, dialog: DialogType) => void;
  onCancel?: (dialoger: DialogStore, dialog: DialogType) => void;
  footer?: boolean;
  dialoger: DialogStore;
};

type DialogOptions = {
  onClose?: (id: string) => void;
};

// this is the
class DialogStore extends Store<{ dialogs: DialogType[] }, DialogOptions> {
  closeDialog = (id: string) => {
    this.setValue((state) => {
      const dialogs = state.dialogs.filter((dialog) => dialog.id !== id);
      return { dialogs };
    });
  };

  private _openDialog = (dialog: DialogType) => {
    this.setValue((state) => {
      const dialogs = [...state.dialogs, dialog];
      return { dialogs };
    });
  };

  openDialog = ({
    footer = true,
    ...dialog
  }: Omit<DialogType, "id" | "open" | "dialoger">) => {
    const id = _.uniqueId();
    this._openDialog({
      id,
      ...dialog,
      open: true,
      footer,
      dialoger: this,
    });
  };
}

const [DialogerInstanceSafeProvider, useDialoger] = createSafeContext<{
  dialoger: DialogStore;
}>("Dialoger instance");

export function DialogerProvider({ children }: { children: ReactNode }) {
  const dialoger = useMemo(() => new DialogStore({ dialogs: [] }), []);
  return (
    <DialogerInstanceSafeProvider value={{ dialoger }}>
      {children}
    </DialogerInstanceSafeProvider>
  );
}

export { useDialoger };

export function Dialoger() {
  const { dialoger } = useDialoger();
  const { dialogs } = useStore(dialoger);

  return (
    <>
      {dialogs.map((dialog) => (
        <Dialog
          key={dialog.id}
          open={dialog.open}
          onOpenChange={(open) => {
            // The commented code prevents the dialog from closing when pressing `escape` or click outside the dialog (the overlay)
            // if (dialog.onConfirm || dialog.onCancel) {
            //   return;
            // }

            if (open) {
              dialoger.openDialog(dialog);
            } else {
              dialoger.closeDialog(dialog.id);
            }
          }}
        >
          <DialogContent forceMount>
            {dialog.body ? (
              dialog.body
            ) : (
              <>
                <DialogHeader>
                  <DialogTitle className="text-start">
                    {dialog.title}
                  </DialogTitle>
                  <DialogDescription>{dialog.description}</DialogDescription>
                </DialogHeader>
                <div className="w-full">
                  {typeof dialog.content === "function"
                    ? dialog.content(dialoger, dialog)
                    : dialog.content}
                </div>
                {dialog.footer && (
                  <DialogFooter className="flex items-center justify-end">
                    <DialogClose asChild>
                      <Button
                        onClick={() => {
                          if (dialog.onCancel) {
                            dialog.onCancel(dialoger, dialog);
                          }
                        }}
                        variant="outline"
                      >
                        {dialog.cancelText ?? "Cancel"}
                      </Button>
                    </DialogClose>
                    <Button
                      onClick={() => {
                        if (dialog.onConfirm) {
                          dialog.onConfirm(dialoger, dialog);
                        }
                      }}
                    >
                      {dialog.confirmText ?? "OK"}
                    </Button>
                  </DialogFooter>
                )}
              </>
            )}
          </DialogContent>
        </Dialog>
      ))}
    </>
  );
}
